import {css, styled, Theme} from "@mui/material";
import IconButton from "@mui/material/IconButton";

export const StyledMenuButton = styled(IconButton)(({theme}: {theme: Theme}) => css`
${(baseStyle({theme}))} 
`);
export const StyledImage = styled('img')(({theme}: {theme: Theme}) => css`
${(baseStyle({theme}))} 
`);
export const baseStyle = ({theme}: {theme: Theme}) => css`
margin-left: ${theme.spacing(2)};
max-height: 48px;
`;
