import * as React from 'react';
//import classNames from 'classnames';

import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
//
import {observer} from "mobx-react-lite";
import {useNavigate} from "react-router";
import {LanguageCtx} from "../../stores/LanguageCtx";
import {styled} from "@mui/material";
import {StyledMenuButton} from "./styles";
import {TopBarMenu} from "./TopBarMenu";
import {StartId as SiteLogo} from "../custom-icons/StartId";

const StyledAppBar = styled(AppBar)(({theme}) => `
z-index: ${theme.zIndex.drawer} + 1;
transition: ${theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
})}
`);

const StyledToolbar = styled(Toolbar)(({theme}) => `
    padding-right: ${theme.spacing(2)}; // keep right padding when drawer closed
    text-align: center;
    ${theme.breakpoints.down('sm')} {
        padding-right: ${theme.spacing(0)};
    }
`);

type PropTy = {
    onOpenDrawer: () => unknown,
    onRequestLogin: () => unknown,
    onRequestLogout: () => unknown,
    onOpenAccountManagement: () => unknown,
    onOpenMessageCenter: () => unknown,
    currentLanguage: string,
    loggedIn: boolean,
}

export const TopBar = observer(function TopBar(props: PropTy) {
    const {
        onOpenDrawer,
        onRequestLogin,
        onRequestLogout,
        onOpenAccountManagement,
        onOpenMessageCenter,
        loggedIn,
    } = props;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [language,] = React.useContext(LanguageCtx);
    const navigator = useNavigate();


    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    function handleDrawerOpen() {
        onOpenDrawer();
    }

    return (
        <StyledAppBar
            position="relative"
        >
            <StyledToolbar disableGutters={true}>
                <StyledMenuButton color={"inherit"} onClick={() => navigator('/')}>
                    <SiteLogo/>
                </StyledMenuButton>
                <Typography
                    component="h1"
                    variant="h6"
                    color="inherit"
                    noWrap
                    sx={{
                        flexGrow: 1,
                        fontFamily: "din-2014, sans-serif",
                        fontWeight: 600,
                        fontStyle: "normal",
                        fontSize: "1.5rem",
                    }}
                >
                    {process.env.REACT_APP_NAME}
                </Typography>
                <TopBarMenu onOpenAccountManagement={onOpenAccountManagement}
                            onOpenMessageCenter={onOpenMessageCenter}
                            onRequestLogin={onRequestLogin}
                            onRequestLogout={onRequestLogout}
                            loggedIn={loggedIn}
                />
            </StyledToolbar>
        </StyledAppBar>

    )
});

